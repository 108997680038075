/* eslint-disable */
import React, { Component } from "react";
import cart from "../../common/images/cart.svg";
import barrow from "../../common/images/back-arrow.svg";
import yet from "../../common/images/not-yet.svg";
import mgreen from "../../common/images/map-pin-green.svg";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <a href="#" className="arrow-back">
                <img src={barrow} />
              </a>
            </div>
            <div className="ha-middle">Add Address</div>
            <div className="ha-rhs"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="location-pin-select">
              <div className="lo-dp">
                Jakarta <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
              <div className="lo-search">
                <input type="text" placeholder="Search location..." />
                <img src={mgreen} className="pin-left" />
              </div>
            </div>
            <div className="no-add-yet textcenter">
              <img src={yet} />
              <h2>
                You haven't created a delivery
                <br /> address yet
              </h2>
              <p>Please create a delivery address first</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
