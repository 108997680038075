/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import barrow from "../../common/images/back-arrow.svg";
import yet from "../../common/images/not-yet.svg";
import mgreen from "../../common/images/map-pin-green.svg";
class Deliverystate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stateList: [
        { alphabet: "A", state: ["Aceh"] },
        { alphabet: "B", state: ["Bali", "Banten", "Bengkulu"] },
        { alphabet: "D", state: ["Daerah Istimewa Yogyakarta", "DKI Jakarta"] },
        { alphabet: "G", state: ["Gorontalo"] },
        {
          alphabet: "J",
          state: ["Jambi", "Jawa Barat", "Jawa Tengah", "Jawa Timur"],
        },
        {
          alphabet: "K",
          state: [
            "Kalimantan Barat",
            "Kalimantan Selatan",
            "Kalimantan Tengah",
            "Kalimantan Timur",
            "Kalimantan Utara",
            "Kepulauan Bangka Belitung",
            "Kepulauan Riau",
          ],
        },
        { alphabet: "L", state: ["Lampung"] },
        { alphabet: "M", state: ["Maluku", "Maluku Utara"] },
        {
          alphabet: "N",
          state: ["Nusa Tenggara Barat", "Nusa Tenggara Timur"],
        },
        {
          alphabet: "P",
          state: [
            "Papua",
            "Papua Barat",
            "Papua Barat Daya",
            "Papua Pegunungan",
            "Papua Selatan",
            "Papua Tengah",
          ],
        },
        { alphabet: "R", state: ["Riau"] },
        {
          alphabet: "S",
          state: [
            "Sulawesi Barat",
            "Sulawesi Selatan",
            "Sulawesi Tengah",
            "Sulawesi Tenggara",
            "Sulawesi Utara",
            "Sumatera Barat",
            "Sumatera Selatan",
            "Sumatera Utara",
          ],
        },
      ],
      displayState: "",
      searchKeyWord: "",
    };
  }
  componentDidMount() {
    this.displayState();
  }
  handleChangeEvent(event) {
    this.setState({ searchKeyWord: event.target.value }, function () {
      this.displayState();
    });
  }
  displayState() {
    var displayState = "";
    var totalList = 0;
    if (this.state.stateList.length > 0) {
      displayState = (
        <div className="address-state-list">
          <ul>
            {
              (displayState = this.state.stateList.map((item, index) => {
                var pricelist = item.state;
                if (this.state.searchKeyWord !== "") {
                  var toLocaleLowerCase =
                    this.state.searchKeyWord.toLocaleLowerCase();
                  var search = new RegExp(toLocaleLowerCase, "i"); // prepare a regex object
                  pricelist = item.state.filter((searchData) =>
                    search.test(searchData.toLocaleLowerCase())
                  );
                }
                if (pricelist.length > 0) {
                  totalList++;
                  return (
                    <li key={index}>
                      <div className="container">
                        <h3>{item.alphabet}</h3>
                      </div>
                      <div className="container">
                        {item.state.length > 0 && (
                          <ul className="list-state-inner">
                            {item.state.map((statename, stateIndex) => {
                              return (
                                <li key={stateIndex}>
                                  <a
                                    href={void 0}
                                    onClick={this.selectState.bind(
                                      this,
                                      statename
                                    )}
                                  >
                                    {statename}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </li>
                  );
                }
              }))
            }
          </ul>
        </div>
      );
    }
    if (totalList === 0) {
      displayState = (
        <div className="no-add-yet textcenter">
          <img src={yet} />
          <h2>State No available</h2>
          <p>Please create a delivery address first</p>
        </div>
      );
    }

    this.setState({ displayState: displayState });
  }
  selectState(state) {
    this.props.history.push({
      pathname: "/delivery-location",
      state: {
        statename: state,
      },
    });
  }
  render() {
    return (
      <div className="main-div delivery-state">
        <div className="header-action">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle-search rel">
              <img src={mgreen} className="pin-left" />
              <input
                placeholder="Search State..."
                value={this.state.searchKeyWord}
                type="text"
                className="pac-target-input"
                onChange={this.handleChangeEvent.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="content-body rel">
          {this.state.displayState !== "" && this.state.displayState}
        </div>
      </div>
    );
  }
}

export default Deliverystate;
