/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ContentLoader from "react-content-loader";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { format } from "date-fns";
import { headerconfig, voucherUrl } from "../Settings/Config";
import { showPrice } from "../Settings/SettingHelper";

import barrow from "../../common/images/back-arrow.svg";
import sample from "../../common/images/sample.png";

class Myvoucher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      voucherList: [],
      displayVoucher: "",
      openFilterSheet: false,
    };
  }
  componentDidMount() {
    this.loadVoucher();
  }
  loadVoucher() {
    axios
      .get(
        voucherUrl +
          "inventory/v4/member/stock?page=1&size=10&sortBy=&descending=false&search=&type=datatable&status=AVAILABLE&ultra_voucher=NO",
        headerconfig
      )
      .then((res) => {
        if (
          res.data.data !== "" &&
          typeof res.data.data !== undefined &&
          typeof res.data.data !== "undefined"
        ) {
          this.setState(
            {
              voucherList: res.data.data,
              loading: false,
            },
            function () {
              this.displayVoucher();
            }
          );
        }
      });
  }

  displayVoucher() {
    var displayVoucher = "";
    if (this.state.voucherList.length > 0) {
      displayVoucher = this.state.voucherList.map((item, index) => {
        return (
          <li key={index}>
            <div className="product-img">
              <img src={sample} alt="Eats Image" />
            </div>
            <div className="product-desc">
              <h3 className="ot-title">{item.merchant_name}</h3>
              <p>ID.V : {item.merchant_code}</p>
              <div className="pro-action">
                <div className="pro-price">{showPrice(item.total_value)}</div>
                <div className="pro-btn">
                  <a
                    href={void 0}
                    onClick={this.redeemVoucher.bind(this, item)}
                    className="button"
                  >
                    Redeem
                  </a>
                </div>
              </div>
            </div>
            <div className="expire-orange">
              Exp Date:{format(new Date(item.expired_date), "d LLLL yyyy")}
            </div>
          </li>
        );
      });
    }
    this.setState({ displayVoucher: displayVoucher });
  }
  redeemVoucher() {
    this.setState({ openFilterSheet: true });
  }

  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">My Voucher</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="voucher-search">
              <input type="text" placeholder="Voucher Name" />
            </div>
            <div className="pp-tags">
              <ul>
                <li className="active">
                  {" "}
                  <a href="#">All</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">25.000</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">50.000</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">100.000</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">250.000</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="#">275.000</a>{" "}
                </li>
              </ul>
            </div>

            <div className="voucher-list">
              <ul>
                {this.state.loading === true
                  ? Array(1, 2, 3).map((item) => {
                      return (
                        <li key={item}>
                          <ContentLoader viewBox="0 0 380 70">
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="70"
                              height="70"
                            />
                            <rect
                              x="80"
                              y="17"
                              rx="4"
                              ry="4"
                              width="300"
                              height="13"
                            />
                            <rect
                              x="80"
                              y="40"
                              rx="3"
                              ry="3"
                              width="250"
                              height="10"
                            />
                          </ContentLoader>
                        </li>
                      );
                    })
                  : this.state.displayVoucher}
              </ul>
            </div>
          </div>
        </div>
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain nagetive-popup choose-vehicle do-uwant"
          onDismiss={() => this.setState({ openFilterSheet: false })}
        >
          <div className="textcenter">
            <div className="sry-no service-no">
              <h2>Are you sure to redeem this voucher ?</h2>
              <p>Double-check your voucher and select a redemption location</p>
              <Link to={"/"} className="button button-full">
                Use Delivery
              </Link>
              <Link to={"/"} className="button">
                Redeem at Cashier
              </Link>
            </div>
          </div>
        </BottomSheet>
      </div>
    );
  }
}

export default Myvoucher;
