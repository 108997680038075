/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import ContentLoader from "react-content-loader";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { apiUrl, unquieID, deliveryId } from "../Settings/Config";
import {
  showPrice,
  showAlert,
  showBtnLoader,
  hideBtnLoader,
} from "../Settings/SettingHelper";
import Cart from "../Cart/Cart";
import barrow from "../../common/images/back-arrow.svg";
import ot from "../../common/images/outlet-place.png";
var qs = require("qs");
class Discountoffer extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("customerID") !== "" &&
      typeof cookie.load("customerID") !== undefined &&
      typeof cookie.load("customerID") !== "undefined"
        ? cookie.load("customerID")
        : "";
    this.state = {
      userID: userID,
      loading: true,
      discountList: [],
      displayDiscount: [],
      openFilterSheet: true,
      selectedPromo: [],
      cartdetails: [],
      trigerCart: false,
    };
  }
  componentDidMount() {
    this.loadDiscount();
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState({ cartdetails: PropsDt.cartdetails });
    }
  }
  sateValChange = (field, value) => {
    this.setState({ [field]: value });
  };
  loadDiscount() {
    axios
      .get(
        apiUrl +
          "coupon/listPromo?unquieid=" +
          unquieID +
          "&customerID=" +
          this.state.userID
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState(
            {
              discountList: res.data.result.available_promo,
              loading: false,
            },
            function () {
              this.displayDiscount();
            }
          );
        } else {
          this.setState({ loading: false });
        }
      });
  }
  displayDiscount() {
    var displayDiscount = "";
    if (this.state.discountList.length > 0) {
      displayDiscount = this.state.discountList.map((item, index) => {
        return (
          <li key={index}>
            <div className="product-img">
              <img
                src={item.thumbnail !== "" ? item.thumbnail : ot}
                alt="Eats Image"
              />
            </div>
            <div className="product-desc">
              <h3 className="ot-title">
                {item.promo_code}{" "}
                {item.promotion_type === "fixed"
                  ? showPrice(item.maxAmount)
                  : item.promotion_percentage + "%"}
              </h3>
              <p>{item.promotion_title}</p>
              <div className="expire-orange">Exp Date: {item.expiryOn}</div>
            </div>
            <div className="voucher-tick">
              <input
                type="checkbox"
                className="grn-chk-box"
                onChange={this.selectPromo.bind(this, item.promo_code)}
              />
            </div>
          </li>
        );
      });
    }
    this.setState({ displayDiscount: displayDiscount });
  }
  selectPromo(promoCode, event) {
    var selectedPromo = this.state.selectedPromo;
    if (event.target.checked === true) {
      selectedPromo.push(promoCode);
    } else {
      const index = selectedPromo.indexOf(5);
      if (index > -1) {
        selectedPromo.splice(index, 1);
      }
    }
    this.setState({ selectedPromo: selectedPromo });
  }
  applyDiscount() {
    if (this.state.selectedPromo.length > 0) {
      showBtnLoader("use_discount");
      var grandTotal = this.state.cartdetails.grandTotal;
      var totalItem = this.state.cartdetails.totalItem;
      var products = this.loadProducts(this.state.cartdetails.store);
      var postObject = {
        unquieid: unquieID,
        customerID: this.state.userID,
        availabilityID: deliveryId,
        subtotal: grandTotal,
        itemQuantity: totalItem,
        categoryID: products.length > 0 ? products.join(",") : "",
        discountcode:
          this.state.selectedPromo.length > 0
            ? this.state.selectedPromo.join(",")
            : "",
      };
      axios
        .post(apiUrl + "coupon/applyPromo", qs.stringify(postObject))
        .then((res) => {
          hideBtnLoader("use_discount");
          if (res.data.status === "ok") {
            cookie.save("discountDetails", res.data.discountDetails, {
              path: "/",
            });
            this.props.history.push("/transaction-order");
            showAlert("success", "Discount offer applied successfully");
          } else if (res.data.status === "error") {
            showAlert("error", res.data.message);
          }
        });
    } else {
      showAlert("error", "Please select any one promotion");
    }
  }
  loadProducts(products) {
    var result = [];
    if (products.length > 0) {
      products.map((cartitem) => {
        if (cartitem.item.length > 0) {
          cartitem.item.map((item) => {
            result.push(
              item.productID +
                "|" +
                item.itemTotalPrice +
                "|" +
                item.itemQuantity
            );
          });
        }
      });
    }
    return result;
  }

  render() {
    return (
      <div className="main-div">
        <Cart
          sateValChange={this.sateValChange}
          trigerCart={this.state.trigerCart}
        />
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">Discount Offer</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="voucher-list offer-list">
              <ul>
                {this.state.loading === true
                  ? Array(1, 2, 3).map((item) => {
                      return (
                        <li key={item}>
                          <ContentLoader viewBox="0 0 380 70">
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="70"
                              height="70"
                            />
                            <rect
                              x="80"
                              y="17"
                              rx="4"
                              ry="4"
                              width="300"
                              height="13"
                            />
                            <rect
                              x="80"
                              y="40"
                              rx="3"
                              ry="3"
                              width="250"
                              height="10"
                            />
                          </ContentLoader>
                        </li>
                      );
                    })
                  : this.state.displayDiscount}
              </ul>
            </div>
          </div>
        </div>
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain nagetive-popup cart-btm-btn"
          blocking={false}
        >
          <div className="selected-btm">
            {this.state.selectedPromo.length > 0 && (
              <p>{this.state.selectedPromo.length} Discount(s) Selected</p>
            )}
            <a
              href={void 0}
              className="button button-full"
              id="use_discount"
              onClick={this.applyDiscount.bind(this)}
            >
              Use Discount
            </a>
          </div>
        </BottomSheet>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var cartdetailsArr = Array();
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }
  return {
    cartdetails: cartdetailsArr,
  };
};

export default connect(mapStateTopProps)(withRouter(Discountoffer));
