/* eslint-disable */
import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import cart from "../../common/images/cart.svg";
import list from "../../common/images/list.svg";
import wishlist from "../../common/images/wishlist.svg";

import barrow from "../../common/images/back-arrow.svg";
import gold from "../../common/images/point-gold.svg";
import ot from "../../common/images/outlet-place.png";
import ot1 from "../../common/images/outlet-place1.png";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <a href="#" className="arrow-back">
                <img src={barrow} />
              </a>
            </div>
            <div className="ha-middle">Membership</div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <a href="#">
                    <img src={wishlist} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={cart} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="member-list-bg">
              <div className="member-card">
                <div className="member-card-txt">
                  <p>Member Card</p>
                  <h3>1 Member Point(s)</h3>
                </div>
                <div className="member-card-points">
                  <img src={gold} alt="Goldcoin" />
                  <div className="ultra-p">
                    <p>Ultra Point</p>
                    <h2>250</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="member-group-list">
              <ul>
                <li>
                  <a href="#">
                    <div className="member-grp-img">
                      <img src={ot} alt="Outlet" />
                    </div>
                    <div className="member-grp-text">
                      <h4>MAP Group</h4>
                      <p>50.000 point(s)</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="member-grp-img">
                      <img src={ot1} alt="Outlet" />
                    </div>
                    <div className="member-grp-text">
                      <h4>Boga Group</h4>
                      <p>50.000 point(s)</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="member-grp-img">
                      <img src={ot} alt="Outlet" />
                    </div>
                    <div className="member-grp-text">
                      <h4>PT FFI Group</h4>
                      <p>50.000 point(s)</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
