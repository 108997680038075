/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";
import { apiUrl, unquieID } from "../Settings/Config";
import { showPrice, encodeValue } from "../Settings/SettingHelper";
import barrow from "../../common/images/back-arrow.svg";
import favclip from "../../common/images/no-favourite-clip.svg";
import Cart from "../Cart/Cart";
import ReactStars from "react-rating-stars-component";
var base64 = require("base-64");
var qs = require("qs");
class History extends Component {
  constructor(props) {
    super(props);
    var orderType = "";
    if (
      this.props.match.params.orderType !== "" &&
      typeof this.props.match.params.orderType !== undefined &&
      typeof this.props.match.params.orderType !== "undefined"
    ) {
      orderType = this.props.match.params.orderType;
    } else {
      orderType = "on-process";
    }
    this.state = {
      loading: true,
      orderType: orderType,
      orderList: [],
      displyOrder: "",
      onprogress: 0,
      completed: 0,
      canceled: 0,
      openFilterSheet: false,
      orderAgainitem: "",
      cartdetails: "",
      trigerCart: false,
      rating: 0,
    };
  }
  componentDidMount() {
    this.loadOrders();
    $("body").removeClass("bodybgdark");
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.orderType !== PropsDt.match.params.orderType) {
      this.setState(
        { orderType: PropsDt.match.params.orderType, loading: true },
        function () {
          this.loadOrders();
        }
      );
    }
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState({ cartdetails: PropsDt.cartdetails });
    }
  }
  loadOrders() {
    axios
      .get(
        apiUrl +
          "orders/orderList?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID") +
          "&orderType=" +
          this.state.orderType
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var ordercount = res.data.ordercount;

          var onprogress = 0;
          var completed = 0;
          var canceled = 0;
          if (
            ordercount[1] !== "" &&
            typeof ordercount[1] !== undefined &&
            typeof ordercount[1] !== "undefined"
          ) {
            onprogress = parseInt(ordercount[1].orderCount);
          }
          if (
            ordercount[2] !== "" &&
            typeof ordercount[2] !== undefined &&
            typeof ordercount[2] !== "undefined"
          ) {
            onprogress += parseInt(ordercount[2].orderCount);
          }
          if (
            ordercount[3] !== "" &&
            typeof ordercount[3] !== undefined &&
            typeof ordercount[3] !== "undefined"
          ) {
            onprogress += parseInt(ordercount[3].orderCount);
          }
          if (
            ordercount[4] !== "" &&
            typeof ordercount[4] !== undefined &&
            typeof ordercount[4] !== "undefined"
          ) {
            completed = parseInt(ordercount[4].orderCount);
          }
          if (
            ordercount[5] !== "" &&
            typeof ordercount[5] !== undefined &&
            typeof ordercount[5] !== "undefined"
          ) {
            canceled = parseInt(ordercount[5].orderCount);
          }
          this.setState(
            {
              orderList: res.data.result,
              onprogress: onprogress,
              completed: completed,
              canceled: canceled,
            },
            function () {
              this.displayOrder();
            }
          );
        } else {
          this.setState({ loading: false });
        }
      });
  }
  displayOrder() {
    var displyOrder = "";
    if (this.state.orderList.length > 0) {
      displyOrder = this.state.orderList.map((item, index) => {
        var orderClass = "";
        if (
          item.orderStatus === "1" ||
          item.orderStatus === "2" ||
          item.orderStatus === "3"
        ) {
          orderClass = "on-process-list";
        } else if (item.orderStatus === "4") {
          orderClass = "cmpleted-list";
        } else if (item.orderStatus === "5") {
          orderClass = "canceled-list";
        }
        return (
          <div className={"voucher-list " + orderClass} key={index}>
            <ul>
              <li>
                <div className="order-history-info">
                  <span>Delivery - {item.deliverySource}</span>
                  <span>
                    {format(new Date(item.order_date), "dd MMM, y hh:mm a")}
                  </span>
                </div>
                <div className="product-img">
                  {item.store[0].image !== "" && (
                    <img src={item.store[0].image} alt={item.store[0].name} />
                  )}
                </div>
                <div className="product-desc">
                  <h3 className="ot-title">
                    {" "}
                    {item.store.map((storeItem, storeIndex) => {
                      return (
                        <span key={storeIndex}>
                          {storeIndex !== 0 ? ", " : ""}
                          {storeItem.name}
                        </span>
                      );
                    })}{" "}
                    {/* Rp 100.000 */}
                  </h3>
                  <div className="menu-small">
                    <span>{item.totalItem} Menu</span>
                    <strong>{item.status_name}</strong>
                  </div>
                </div>
                <div className="price-and-action">
                  <span>{showPrice(item.totalAmount)}</span>
                  {(this.state.orderType === "on-process" ||
                    this.state.orderType === "canceled") &&
                    (item.delivery_order_details !== "" &&
                    item.delivery_order_details?.trackingUrl ? (
                      <Link
                        to={
                          "/track-order/" +
                          this.state.orderType +
                          "/" +
                          item.orderNumber
                        }
                        className="button"
                      >
                        Track Order
                      </Link>
                    ) : (
                      <Link
                        to={
                          "/history/" +
                          this.state.orderType +
                          "/" +
                          item.orderNumber
                        }
                        className="button"
                      >
                        View Details
                      </Link>
                    ))}
                  {this.state.orderType === "completed" && (
                    <a
                      href={void 0}
                      onClick={this.orderAgain.bind(this, item)}
                      className="button"
                    >
                      Order Again
                    </a>
                  )}
                </div>
                <ReactStars
                  count={5}
                  onChange={this.ratingChanged.bind(
                    this,
                    item.order_primary_id
                  )}
                  size={24}
                  value={
                    item.order_rating_option !== "" &&
                    item.order_rating_option !== null
                      ? parseFloat(item.order_rating_option)
                      : 0
                  }
                  activeColor="#ffd700"
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  edit={parseFloat(item.order_rating_option) > 0 ? false : true}
                />
              </li>
            </ul>
          </div>
        );
      });
    }
    this.setState({ displyOrder: displyOrder, loading: false });
  }
  ratingChanged = (orderID, newRating) => {
    console.log(newRating, orderID, "namename");
    var postObject = {
      unquieid: unquieID,
      customerID: cookie.load("customerID"),
      orderID: base64.encode(orderID),
      rating: newRating,
    };
    axios
      .post(apiUrl + "orders/createRating", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var updateOrders = [];
          if (this.state.orderList.length > 0) {
            this.state.orderList.map((item) => {
              if (item.order_primary_id === orderID) {
                console.log(item.order_primary_id, orderID, "orderIDorderID");
                item["order_rating_option"] = newRating;
              }
              updateOrders.push(item);
            });
          }
          console.log(updateOrders, "updateOrders");
          this.setState({ orderList: updateOrders }, function () {
            this.displayOrder();
          });
        }
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
      });
  };
  /*  changeRating(newRating, name) {
    console.log(newRating, "newRating");
    this.setState({
      rating: newRating,
    });
  } */
  orderAgain(orderAgainitem) {
    console.log(this.state.cartdetails, "this.state.cartdetails");
    if (this.state.cartdetails !== "") {
      this.setState(
        { orderAgainitem: orderAgainitem, openFilterSheet: true },
        () => {
          $("body").addClass("bodybgdark");
        }
      );
    }
  }

  confirmorderAgain() {
    var postObject = {
      unquieid: unquieID,
      customerID: cookie.load("customerID"),
    };
    axios
      .post(apiUrl + "cart/removeCart", qs.stringify(postObject))
      .then((res) => {
        var orderpostObject = {
          unquieid: unquieID,
          customerID: cookie.load("customerID"),
          orderID: encodeValue(this.state.orderAgainitem.order_primary_id),
        };
        console.log(res, orderpostObject, "resresres");
        axios
          .post(apiUrl + "orders/orderAgain", qs.stringify(orderpostObject))
          .then((res) => {
            if (res.data.status === "ok") {
              this.props.history.push("/transaction-order");
            }
          });
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
      });
  }

  sateValChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    return (
      <div className="main-div">
        <Cart
          sateValChange={this.sateValChange}
          trigerCart={this.state.trigerCart}
        />
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/"} className="arrow-back">
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">History</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="pp-tags history-tags">
              <ul>
                <li
                  className={
                    this.state.orderType === "on-process" ? "active" : ""
                  }
                >
                  <Link to={"/history/on-process"}>
                    On Process{" "}
                    {this.state.onprogress > 0
                      ? "(" + this.state.onprogress + ")"
                      : ""}
                  </Link>
                </li>
                <li
                  className={
                    this.state.orderType === "completed" ? "active" : ""
                  }
                >
                  <Link to={"/history/completed"}>
                    Completed{" "}
                    {this.state.completed > 0
                      ? "(" + this.state.completed + ")"
                      : ""}
                  </Link>
                </li>
                <li
                  className={
                    this.state.orderType === "canceled" ? "active" : ""
                  }
                >
                  <Link to={"/history/canceled"}>
                    Canceled{" "}
                    {this.state.canceled > 0
                      ? "(" + this.state.canceled + ")"
                      : ""}
                  </Link>
                </li>
              </ul>
            </div>
            {this.state.loading === false &&
              this.state.orderList.length === 0 && (
                <div className="no-favtxt">
                  <img src={favclip} />
                  <h2>There is no order</h2>
                </div>
              )}
            {this.state.loading === true ? (
              <div className="voucher-list on-process-list">
                <ul>
                  {Array(1, 2, 3).map((item) => {
                    return (
                      <li key={item}>
                        <ContentLoader viewBox="0 0 380 70">
                          <rect
                            x="0"
                            y="0"
                            rx="5"
                            ry="5"
                            width="70"
                            height="70"
                          />
                          <rect
                            x="80"
                            y="17"
                            rx="4"
                            ry="4"
                            width="300"
                            height="13"
                          />
                          <rect
                            x="80"
                            y="40"
                            rx="3"
                            ry="3"
                            width="250"
                            height="10"
                          />
                        </ContentLoader>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              this.state.displyOrder
            )}
          </div>
        </div>
        {this.state.openFilterSheet === true && (
          <footer className="footer-main">
            <IonFooter collapse="fade">
              {/*  Cancel Order */}
              <div className="do-uwant-popup">
                <p>Do you want to re order?</p>
                <div className="do-ubtn">
                  <a
                    href={void 0}
                    className="button light-danger"
                    onClick={() =>
                      this.setState({ openFilterSheet: false }, () => {
                        $("body").removeClass("bodybgdark");
                      })
                    }
                  >
                    No
                  </a>
                  <a
                    href={void 0}
                    onClick={this.confirmorderAgain.bind(this)}
                    className="button dark-danger"
                  >
                    Yes
                  </a>
                </div>
              </div>
            </IonFooter>
          </footer>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var cartdetailsArr = Array();
  console.log(state.cartdetails, "state.cartdetails");
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }
  return {
    cartdetails: cartdetailsArr,
  };
};

export default connect(mapStateTopProps)(withRouter(History));
