/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import cookie from "react-cookies";
import "react-spring-bottom-sheet/dist/style.css";
import { apiUrl, unquieID } from "../Settings/Config";
import barrow from "../../common/images/back-arrow.svg";
import { IonFooter } from "@ionic/react";
import "@ionic/react/css/core.css";

class TrackOrder extends Component {
  constructor(props) {
    super(props);
    var orderType = "";
    if (
      this.props.match.params.orderType !== "" &&
      typeof this.props.match.params.orderType !== undefined &&
      typeof this.props.match.params.orderType !== "undefined"
    ) {
      orderType = this.props.match.params.orderType;
    } else {
      orderType = "on-process";
    }
    var orderNumber = this.props.match.params?.orderNumber || "";
    this.state = {
      loading: true,
      orderType: orderType,
      orderNumber: orderNumber,
      rating: 0,
      displayMap: "",
      orderDetails: "",
    };
  }
  componentDidMount() {
    this.loadOrderDetails();
  }

  loadOrderDetails() {
    axios
      .get(
        apiUrl +
          "orders/orderDetails?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID") +
          "&orderNumber=" +
          this.state.orderNumber
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState(
            {
              orderDetails: res.data.result[0],
              loading: false,
            },
            function () {
              this.displyMap();
            }
          );
        } else {
          this.props.history.push("/history");
        }
      });
  }
  displyMap() {
    if (this.state.orderDetails !== "") {
      console.log(this.state.orderDetails, "this.state.orderDetails");
      var delivery_order_details =
        this.state.orderDetails?.delivery_order_details;
      if (delivery_order_details !== "") {
        var displayMap = (
          <iframe
            src={delivery_order_details.trackingUrl}
            title="description"
            width={"100%"}
            height={`${$(document).height() - 125}`}
          ></iframe>
        );
        this.setState({ displayMap: displayMap });
      }
    }
  }

  render() {
    console.log($(document).height(), "${$(document).height()");
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link
                to={"/history/" + this.state.orderType}
                className="arrow-back"
              >
                <img src={barrow} />
              </Link>
            </div>
            <div className="ha-middle">Delivery Tracking</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>

        <div className="content-body rel">
          <div className="container">
            {this.state.displayMap !== "" ? (
              this.state.displayMap
            ) : (
              <ContentLoader viewBox="0 0 380 70">
                <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
              </ContentLoader>
            )}
          </div>
        </div>
        <IonFooter collapse="fade" className="track-order-btoom">
          <div className="do-uwant-popup ">
            <div className="do-ubtn">
              <Link
                to={
                  "/history/" +
                  this.state.orderType +
                  "/" +
                  this.state.orderNumber
                }
                className="button add-anr-btn"
              >
                View Details
              </Link>
            </div>
          </div>
        </IonFooter>
      </div>
    );
  }
}

export default TrackOrder;
