/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
/* import { Million } from "million"; */
import { GET_STORE_LIST, GET_VOUCHER_LIST } from "../../actions";
import { apiUrl, unquieID, deliveryId, baseUrl } from "../Settings/Config";
import {
  encodeValue,
  showPrice,
  stripslashes,
} from "../Settings/SettingHelper";
import Cart from "../Cart/Cart";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import cart from "../../common/images/cart.svg";
import list from "../../common/images/list.svg";
import wishlist from "../../common/images/wishlist.svg";
import mpin from "../../common/images/map-pin-green.svg";
import mpinlight from "../../common/images/map-pin-green-light.svg";
import ypin from "../../common/images/map-pin-yellow.svg";
import gold from "../../common/images/point-gold.svg";
import barrow from "../../common/images/back-arrow.svg";

import vicon from "../../common/images/voucher-icon.svg";
import hicon from "../../common/images/history-icon.svg";
import searchg from "../../common/images/search-green.svg";
import ot from "../../common/images/outlet-place.png";
import chn from "../../common/images/Chicken.png";
import bur from "../../common/images/Burger.png";
import cke from "../../common/images/Cake.png";
import ndle from "../../common/images/Noddle.png";
import piz from "../../common/images/Pizza.png";
import cha from "../../common/images/cha-time.png";
import auntt from "../../common/images/aunt.png";
import sbks from "../../common/images/starbuks.png";
import heartline from "../../common/images/heart.svg";
import gchat from "../../common/images/chat-icon.svg";
import gbike from "../../common/images/green-bike.svg";
import gspoon from "../../common/images/green-spoon.svg";
import tele from "../../common/images/telephone-icon.svg";
import welcomeuv from "../../common/images/welcome-uv.svg";
import restaurantclose from "../../common/images/restaurant-close.svg";

var Parser = require("html-react-parser");
var mbanner = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
  margin: 15,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      stagePadding: 20,
    },
    480: {
      items: 1,
      margin: 15,
      stagePadding: 40,
    },
  },
};

var foodbanner = {
  items: 4,
  loop: true,
  dots: false,
  nav: false,
  margin: 13,
  stagePadding: 30,
};

var recmdbanner = {
  items: 2,
  loop: true,
  dots: false,
  nav: false,
  margin: 13,
  stagePadding: 40,
  responsive: {
    0: {
      items: 1,
      stagePadding: 40,
    },
    380: {
      items: 2,
      stagePadding: 30,
    },
  },
};
var qs = require("qs");
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagList: [],
      bannerList: [],
      storeList: [],
      storeDisplay: "",
      loading: true,
      locationName:
        cookie.load("locationName") !== "" &&
        typeof cookie.load("locationName") !== undefined &&
        typeof cookie.load("locationName") !== "undefined"
          ? cookie.load("locationName")
          : "",
      locationImage:
        cookie.load("locationImage") !== "" &&
        typeof cookie.load("locationImage") !== undefined &&
        typeof cookie.load("locationImage") !== "undefined"
          ? cookie.load("locationImage")
          : "",
      cartdetails: [],
      voucherlist: "",
      totalfavourite: "0",
      onGoingOrder: [],
      displyonGoingOrder: "",
      delZoneID:
        cookie.load("delZoneID") !== "" &&
        typeof cookie.load("delZoneID") !== undefined &&
        typeof cookie.load("delZoneID") !== "undefined"
          ? cookie.load("delZoneID")
          : "",
      recomentList: [],
      inititalLoad: false,
      recomentDisplay: "",
      displaySearch: "",
      searchProductList: [],
      searchKeywords: "",
      filterTagID: [],
    };
  }
  componentDidMount() {
    $("body").removeClass("bodybgdark");
    this.displyaSearchresult();
    this.getSearchProducts();
    const searchParams = new URLSearchParams(document.location.search);
    var token = searchParams.get("token");
    var refresh_token = searchParams.get("refresh_token");
    console.log(token, "tokentoken");
    if (
      token !== "" &&
      token !== null &&
      typeof token !== "undefined" &&
      typeof token !== undefined
    ) {
      cookie.save("token", token, { path: "/" });
      this.loadUserDetails(token);
      if (
        refresh_token !== "" &&
        typeof refresh_token !== "undefined" &&
        typeof refresh_token !== undefined
      ) {
        cookie.save("refresh_token", refresh_token, { path: "/" });
      }
    } else {
      if (this.state.delZoneID === "") {
        this.setState({ inititalLoad: true });
        $("body").addClass("welcomeuv-bg");
      }
    }

    this.loadTag();
    this.loadBanner();
    this.loadTotalFav();
    this.loadOngoinOrders();
    /* this.props.getVoucherList(); */
    this.loadRecomentPro();
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.storeList !== PropsDt.storeList) {
      this.setState({ storeList: PropsDt.storeList }, function () {
        this.displayStore();
      });
    }
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState({ cartdetails: PropsDt.cartdetails });
    }
    /* if (this.state.voucherlist !== PropsDt.voucherlist) {
      if (
        PropsDt.voucherlist.data !== "" &&
        typeof PropsDt.voucherlist.data !== undefined &&
        typeof PropsDt.voucherlist.data !== "undefined"
      ) {
        this.setState({ voucherlist: PropsDt.voucherlist });
      }
    } */
  }
  getSearchProducts() {
    var latitude =
      cookie.load("deliveryaddresslat") !== "" &&
      typeof cookie.load("deliveryaddresslat") !== undefined &&
      typeof cookie.load("deliveryaddresslat") !== "undefined"
        ? cookie.load("deliveryaddresslat")
        : "";
    var longitude =
      cookie.load("deliveryaddresslong") !== "" &&
      typeof cookie.load("deliveryaddresslong") !== undefined &&
      typeof cookie.load("deliveryaddresslong") !== "undefined"
        ? cookie.load("deliveryaddresslong")
        : "";
    axios
      .get(
        apiUrl +
          "catalogs/searchproducts?unquieid=" +
          unquieID +
          "&locationID=" +
          cookie.load("locationID") +
          "&latitude=" +
          latitude +
          "&longitude=" +
          longitude +
          "&avilability=" +
          deliveryId
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ searchProductList: res.data.result });
        }
      });
  }
  loadUserDetails(token) {
    var postObject = {
      unquieid: unquieID,
      token: token,
    };

    axios
      .post(apiUrl + "customers/login", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var result = res.data.result;
          cookie.save("firstName", result.firstName, { path: "/" });
          cookie.save(
            "lastName",
            result.lastName !== "" && result.lastName !== null
              ? result.lastName
              : "",
            { path: "/" }
          );
          cookie.save("email", result.email, { path: "/" });
          cookie.save("primary_phone", result.primary_phone, { path: "/" });
          cookie.save("customerUVID", encodeValue(result.customerUVID), {
            path: "/",
          });
          cookie.save("customerID", encodeValue(result.customerID), {
            path: "/",
          });
          var address = result.address;
          if (address !== "" && address !== null) {
            cookie.save("deliveryaddress", address.address, { path: "/" });
            cookie.save("deliverycountry", address.country, { path: "/" });
            cookie.save("deliverystate", address.state, { path: "/" });
            cookie.save("deliverypostalCode", address.postal_code, {
              path: "/",
            });
            cookie.save("deliverycity", address.city, { path: "/" });
            cookie.save("deliverydistrict", address.district, { path: "/" });
            cookie.save("deliveryaddresslat", address.latitude, {
              path: "/",
            });
            cookie.save("deliveryaddresslong", address.longitude, {
              path: "/",
            });
            cookie.save("addressID", address.address_id, {
              path: "/",
            });
            var location = result.pickupAddress;
            this.selectLocation(location);
          } else {
            this.setState({ inititalLoad: true }, () => {
              $("body").addClass("welcomeuv-bg");
              this.props.history.push("/");
            });
          }
        } else if (res.data.status === "error") {
        }
      });
  }

  selectLocation(location) {
    var latitude =
      cookie.load("deliveryaddresslat") !== "" &&
      typeof cookie.load("deliveryaddresslat") !== undefined &&
      typeof cookie.load("deliveryaddresslat") !== "undefined"
        ? cookie.load("deliveryaddresslat")
        : "";
    var longitude =
      cookie.load("deliveryaddresslong") !== "" &&
      typeof cookie.load("deliveryaddresslong") !== undefined &&
      typeof cookie.load("deliveryaddresslong") !== "undefined"
        ? cookie.load("deliveryaddresslong")
        : "";
    axios
      .get(
        apiUrl +
          "store/findDeliveryZone?unquieid=" +
          unquieID +
          "&locationID=" +
          location.locationID +
          "&latitude=" +
          latitude +
          "&longitude=" +
          longitude +
          "&avilability=" +
          deliveryId
      )
      .then((res) => {
        if (res.data.status === "ok") {
          cookie.save("delZoneID", encodeValue(res.data.result.delZoneID), {
            path: "/",
          });
          cookie.save("delZoneName", res.data.result.delZoneName, {
            path: "/",
          });
          cookie.save("locationID", encodeValue(location.locationID), {
            path: "/",
          });
          cookie.save("locationName", location.locationName, { path: "/" });
          cookie.save("locationAddress", location.address, {
            path: "/",
          });
          cookie.save("locationImage", location.image, { path: "/" });
          window.location.href = baseUrl;
        } else {
          this.setState({ inititalLoad: true }, () => {
            $("body").addClass("welcomeuv-bg");
          });
        }
      });
  }

  loadTotalFav() {
    axios
      .get(
        apiUrl +
          "store/getTotalFavourite?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID")
      )
      .then((res) => {
        console.log(res.data, "res.data");
        this.setState({ totalfavourite: res.data.totalfavourite });
      });
  }
  loadTag() {
    axios.get(apiUrl + "store/tagList?unquieid=" + unquieID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ tagList: res.data.result }, function () {
          if (
            cookie.load("locationID") !== "" &&
            typeof cookie.load("locationID") !== undefined &&
            typeof cookie.load("locationID") !== "undefined"
          ) {
            var latitude =
              cookie.load("deliveryaddresslat") !== "" &&
              typeof cookie.load("deliveryaddresslat") !== undefined &&
              typeof cookie.load("deliveryaddresslat") !== "undefined"
                ? cookie.load("deliveryaddresslat")
                : "";
            var longitude =
              cookie.load("deliveryaddresslong") !== "" &&
              typeof cookie.load("deliveryaddresslong") !== undefined &&
              typeof cookie.load("deliveryaddresslong") !== "undefined"
                ? cookie.load("deliveryaddresslong")
                : "";
            this.props.getStoreList(
              "&dellocation=" +
                cookie.load("locationID") +
                "&latitude=" +
                latitude +
                "&longitude=" +
                longitude
            );
          }
        });
      }
    });
  }
  loadBanner() {
    axios.get(apiUrl + "banner/listBanner?unquieid=" + unquieID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ bannerList: res.data.result });
      }
    });
  }
  displayStore() {
    var storeDisplay = "";
    if (this.state.storeList.length > 0) {
      storeDisplay = this.state.storeList.map((item, index) => {
        var splitTag =
          item.tagID !== "" && item.tagID !== null ? item.tagID.split(",") : [];
        var validTag = this.validateTag(splitTag);
        if (validTag === "Yes") {
          return (
            <li key={index}>
              <Link to={"/restaurant/" + item.storeSlug} className="ot-parent">
                <div
                  className={`ot-img ${
                    item?.storeOpen === "0"
                      ? "storeclosed inner-store-close"
                      : ""
                  }`}
                >
                  <img
                    src={
                      item.storeImage !== "" && item.storeImage !== null
                        ? item.storeImage
                        : ot
                    }
                    alt={item.storeName}
                  />
                </div>
                <div className="ot-info">
                  {item.storeOpen === "0" && (
                    <div className="storeclosed-msg">
                      <img src={restaurantclose} alt="Restaurant is closed" />
                      <span>Restaurant is closed</span>
                    </div>
                  )}
                  <div
                    className={`${
                      item?.storeOpen === "0"
                        ? "storeclosed inner-store-close"
                        : ""
                    }`}
                  >
                    <h3 className="ot-title">{item.storeName}</h3>
                    <div className="km-rating">
                      <strong>
                        {item.distance !== ""
                          ? parseFloat(item.distance).toFixed("2")
                          : "0"}{" "}
                        km
                      </strong>
                      {item.Rating !== "" && item.Rating !== null && (
                        <span>
                          {item.Rating} ({item.totalRating})
                        </span>
                      )}
                    </div>
                    <div className="op-time">{item.storeTimeInfo}</div>
                    {item.tagID !== "" &&
                      item.tagID !== null &&
                      this.state.tagList.length > 0 && (
                        <div className="ot-keyword">
                          {this.loadStoreTag(item.tagID)}
                        </div>
                      )}
                    {item.offerInfo !== "" && item.offerInfo !== null && (
                      <div className="ot-offers">{item.offerInfo}</div>
                    )}
                  </div>
                </div>
              </Link>
            </li>
          );
        }
      });
    }
    this.setState({ storeDisplay: storeDisplay, loading: false });
  }
  validateTag(storeTag) {
    var validateTags = "No";
    if (this.state.filterTagID.length > 0) {
      this.state.filterTagID.map((item) => {
        if (storeTag.indexOf(item) >= 0) {
          validateTags = "Yes";
        }
      });
    } else {
      validateTags = "Yes";
    }
    return validateTags;
  }

  loadStoreTag(storeTag) {
    if (this.state.tagList.length > 0) {
      var storeTag = storeTag.split(",");
      var tagList = [];
      this.state.tagList.map((item) => {
        if (storeTag.indexOf(item.value) >= 0) {
          tagList.push(item.label);
        }
      });
      if (tagList.length > 0) {
        return tagList.join(", ");
      }
    }
  }
  loadOngoinOrders() {
    axios
      .get(
        apiUrl +
          "orders/orderList?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID") +
          "&orderType=Ongoing"
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ onGoingOrder: res.data.result }, function () {
            this.displayOngoingOrder();
          });
        }
      });
  }
  displayOngoingOrder() {
    var displyonGoingOrder = "";
    if (this.state.onGoingOrder.length > 0) {
      displyonGoingOrder = this.state.onGoingOrder.map((item, index) => {
        return (
          <div className="og-box mb10" key={index}>
            <div className="ogbox-img">
              <img src={gbike} alt="Bike" />
            </div>
            <div className="ogbox-txt">
              <span>Driver On The Way</span>
              <h3>
                {item.store.map((storeItem, storeIndex) => {
                  return (
                    <span key={storeIndex}>
                      {storeIndex !== 0 ? ", " : ""}
                      {storeItem.name}
                    </span>
                  );
                })}
              </h3>
              <div className="ogbox-btn home-orders">
                <a
                  href={
                    item.order_driver_phone !== "" &&
                    item.order_driver_phone !== null
                      ? "tel:" + item.order_driver_phone
                      : void 0
                  }
                  className="button btn-ghost"
                >
                  <img src={tele} alt="Telephone" /> Call Driver
                </a>
                <a
                  href={
                    item.order_driver_phone !== "" &&
                    item.order_driver_phone !== null
                      ? `https://web.whatsapp.com/send/?phone=${item.order_driver_phone}&text&type=phone_number&app_absent=0`
                      : void 0
                  }
                  className="button btn-normal home-chat-order"
                >
                  <img src={gchat} alt="Chat" /> Chat Driver
                </a>

                {item.delivery_order_details !== "" &&
                item.delivery_order_details?.trackingUrl ? (
                  <Link
                    to={
                      "/track-order/" +
                      this.state.orderType +
                      "/" +
                      item.orderNumber
                    }
                    className="button btn-ghost home-track-order"
                  >
                    <img src={mpinlight} alt="track" />
                    Track Order
                  </Link>
                ) : (
                  <Link
                    to={
                      "/history/" +
                      this.state.orderType +
                      "/" +
                      item.orderNumber
                    }
                    className="button btn-ghost home-track-order"
                  >
                    <img src={mpinlight} alt="track" /> View Details
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displyonGoingOrder: displyonGoingOrder });
  }
  setAddress() {
    $("body").removeClass("welcomeuv-bg").css("overflow", "auto");
    this.props.history.push("/delivery-state");
  }
  loadRecomentPro() {
    var latitude =
      cookie.load("deliveryaddresslat") !== "" &&
      typeof cookie.load("deliveryaddresslat") !== undefined &&
      typeof cookie.load("deliveryaddresslat") !== "undefined"
        ? cookie.load("deliveryaddresslat")
        : "";
    var longitude =
      cookie.load("deliveryaddresslong") !== "" &&
      typeof cookie.load("deliveryaddresslong") !== undefined &&
      typeof cookie.load("deliveryaddresslong") !== "undefined"
        ? cookie.load("deliveryaddresslong")
        : "";
    if (latitude !== "" && longitude !== "") {
      var locationID =
        cookie.load("locationID") !== "" &&
        typeof cookie.load("locationID") !== undefined &&
        typeof cookie.load("locationID") !== "undefined"
          ? cookie.load("locationID")
          : "";
      axios
        .get(
          apiUrl +
            "catalogs/recomentproducts?unquieid=" +
            unquieID +
            "&type=recommendation" +
            "&latitude=" +
            latitude +
            "&longitude=" +
            longitude +
            "&locationID=" +
            locationID
        )
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({ recomentList: res.data.result }, function () {
              this.displyRecoment();
            });
          }
        });
    }
  }
  displyRecoment() {
    var recomentDisplay = "";
    if (this.state.recomentList.length > 0) {
      recomentDisplay = this.state.recomentList.map((item, index) => {
        return (
          <div className="re-parent" key={index}>
            <a to={"#"} className="like">
              {" "}
              <img src={heartline} />{" "}
            </a>
            <Link
              to={
                "/restaurant/" +
                item.outletSlug +
                "/" +
                item.catSlug +
                "/" +
                item.product_slug
              }
              className="main-link"
            >
              <div className="re-mainimg">
                <img
                  src={
                    item.product_thumbnail !== "" ? item.product_thumbnail : cha
                  }
                />
                {item.tagInfo !== "" && (
                  <div className="discount-badge">
                    <div className="discount-badge-inner">
                      <span>UP TO</span>
                      <div className="dark-red">{Parser(item.tagInfo)}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="re-main-desc">
                <h4>
                  {item.product_alias !== "" && item.product_alias !== null
                    ? stripslashes(item.product_alias)
                    : stripslashes(item.product_name)}
                  <br /> {stripslashes(item.outletName)}
                </h4>
                <div className="km-rating">
                  <strong>
                    {item.distance !== ""
                      ? parseFloat(item.distance).toFixed("2")
                      : "0"}{" "}
                    km
                  </strong>
                  {/*<span>4.8 (1,298)</span>*/}
                </div>
              </div>
            </Link>
          </div>
        );
      });
    }

    this.setState({ recomentDisplay: recomentDisplay });
  }
  changeEatsArea() {
    this.props.history.push("/eatsarea");
  }
  changeDeliveryArea() {
    this.props.history.push("/delivery-state");
  }
  searchPro(e) {
    this.setState({ searchKeywords: e.target.value }, () => {
      this.displyaSearchresult();
    });
  }
  displyaSearchresult() {
    var itemDisplay = "";
    var totalSearchPro = 0;
    if (this.state.searchProductList.length > 0) {
      itemDisplay = this.state.searchProductList.map((item, index) => {
        var proName =
          item.product_alias !== "" && item.product_alias !== null
            ? item.product_alias
            : item.product_name;
        if (
          proName
            .toLocaleLowerCase()
            .indexOf(this.state.searchKeywords.toLocaleLowerCase()) >= 0 ||
          item.outletName
            .toLocaleLowerCase()
            .indexOf(this.state.searchKeywords.toLocaleLowerCase()) >= 0
        ) {
          totalSearchPro++;
          return (
            <li key={index}>
              <div className="search-pro-name">
                <Link
                  to={
                    "/restaurant/" +
                    item.outletSlug +
                    "/" +
                    item.catSlug +
                    "/" +
                    item.product_slug
                  }
                >
                  {proName}
                </Link>
                <Link to={"/restaurant/" + item.outletSlug}>
                  <div>{item.outletName}</div>
                </Link>
              </div>
              <div className="pro-price">
                {showPrice(item.product_price)}
                {item.product_cost !== "" &&
                  item.product_cost !== null &&
                  parseFloat(item.product_cost) > 0 && (
                    <div className="special-price">
                      {showPrice(item.product_cost)}
                    </div>
                  )}
              </div>
            </li>
          );
        }
      });
    }
    var searhcResult = "";
    if (this.state.searchKeywords !== "") {
      searhcResult = (
        <ul className="food-search-result">
          {totalSearchPro > 0 ? (
            itemDisplay
          ) : (
            <li>
              <div className="search-pro-name">No Record Found</div>
            </li>
          )}
        </ul>
      );
    }
    this.setState({ displaySearch: searhcResult });
  }
  applyTag(tagID) {
    var filterTagID = this.state.filterTagID;
    if (filterTagID.indexOf(tagID) >= 0) {
      const index = filterTagID.indexOf(tagID);
      if (index > -1) {
        filterTagID.splice(index, 1);
      }
    } else {
      filterTagID.push(tagID);
    }
    this.setState({ filterTagID: filterTagID }, () => {
      this.displayStore();
    });
  }
  render() {
    return (
      <div className="main-div">
        <Cart />
        <div className="header-action">
          <div className="container">
            <div
              className="ha-lhs"
              onClick={this.changeDeliveryArea.bind(this)}
            >
              <Link to={"/delivery-state"} className="arrow-back">
                <img src={barrow} />
              </Link>
              <span>Your Location</span>
              <p title={cookie.load("deliveryaddress")}>
                <img src={mpin} /> {cookie.load("deliveryaddress")}
              </p>
            </div>
            <div className="ha-rhs">
              <ul>
                <li>
                  <Link to={"/history/on-process"}>
                    <img src={list} />
                  </Link>
                </li>
                <li>
                  <Link to={"/favourites"}>
                    <img src={wishlist} />
                    {parseInt(this.state.totalfavourite) > 0 && (
                      <span className="cart-count">
                        {this.state.totalfavourite}
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link to={"/cart"}>
                    <img src={cart} />
                    {Object.keys(this.state.cartdetails).length > 0 && (
                      <span className="wish-count">
                        {this.state.cartdetails.totalItem}+
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            {/* <div className="member-card">
              <div className="member-card-txt">
                <p>Member Card</p>
                <h3>1 Member Point(s)</h3>
              </div>
              <div className="member-card-points">
                <img src={gold} />
                <div className="ultra-p">
                  <p>Ultra Point</p>
                  <h2>250</h2>
                </div>
              </div>
            </div> */}
            {this.state.bannerList.length > 0 && (
              <div className="banner">
                <OwlCarousel options={mbanner}>
                  {this.state.bannerList.map((item, index) => {
                    if (
                      item.banner_image !== "" &&
                      item.banner_image !== null
                    ) {
                      return (
                        <div key={index}>
                          <a
                            href={
                              item.banner_link !== ""
                                ? item.banner_link
                                : void 0
                            }
                            target={
                              item.banner_link !== "" ? "_blank" : "_self"
                            }
                          >
                            <img src={item.banner_image} />
                          </a>
                        </div>
                      );
                    }
                  })}
                </OwlCarousel>
              </div>
            )}
            {/* <div className="useful-links">
              <Link to={"/my-voucher"} className="mv-link">
                <img src={vicon} alt="voucher" /> My Voucher{" "}
                {this.state.voucherlist !== "" && (
                  <span>{this.state.voucherlist.meta.recordsTotal}</span>
                )}
              </Link>
              <Link to={"/history"} className="hp-link">
                <img src={hicon} alt="History" /> History Point
              </Link>
            </div> */}
            {this.state.displyonGoingOrder !== "" && (
              <div className="on-going-home">
                <h2>Ongoing Order ({this.state.onGoingOrder.length})</h2>
                {this.state.displyonGoingOrder}
              </div>
            )}
            <div className="main-search">
              <div className="ms-inner">
                <img src={searchg} alt="voucher" />
                <input
                  type="text"
                  className="input-fill"
                  placeholder="Find your food"
                  onChange={this.searchPro.bind(this)}
                />
              </div>
              {this.state.displaySearch}
            </div>

            {this.state.locationName !== "" && (
              <div
                className="eats-area"
                onClick={this.changeEatsArea.bind(this)}
              >
                <div className="ea-lhs">
                  <span>Eats Area</span>
                  <p>
                    <img src={ypin} /> {this.state.locationName}
                  </p>
                </div>
                <div className="ea-rhs">
                  {this.state.locationImage !== "" && (
                    <img
                      src={this.state.locationImage}
                      alt={this.state.locationName}
                      title={this.state.locationName}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="pp-tags">
              <ul>
                {this.state.tagList.length > 0 &&
                  this.state.tagList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          this.state.filterTagID.indexOf(item.value) >= 0
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          href={void 0}
                          onClick={this.applyTag.bind(this, item.value)}
                        >
                          {item.label}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="outlet-listing">
              <div className="outlet-listing-header">
                <h4>Near Me</h4>
                <Link to={"/restaurants"}>See more</Link>
              </div>
              <div className="outlet-main-list">
                <ul>
                  {this.state.loading === true
                    ? Array(1, 2, 3).map((item) => {
                        return (
                          <li key={item}>
                            <ContentLoader viewBox="0 0 380 70">
                              <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="70"
                                height="70"
                              />
                              <rect
                                x="80"
                                y="17"
                                rx="4"
                                ry="4"
                                width="300"
                                height="13"
                              />
                              <rect
                                x="80"
                                y="40"
                                rx="3"
                                ry="3"
                                width="250"
                                height="10"
                              />
                            </ContentLoader>
                          </li>
                        );
                      })
                    : this.state.storeDisplay}
                </ul>
              </div>
            </div>

            {/* <div className="che-slider">
              <h2>Choose your Eats!</h2>
              <OwlCarousel options={foodbanner}>
                <div>
                  <a href="#">
                    <img src={chn} /> <p>Chicken</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={bur} /> <p>Burger</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={cke} /> <p>Cake</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={piz} /> <p>Pizza</p>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <img src={ndle} /> <p>Noddle</p>
                  </a>
                </div>
              </OwlCarousel>
            </div> */}
            {this.state.recomentDisplay !== "" && (
              <div className="recommand-slider">
                <h2>Recommendation</h2>
                <OwlCarousel options={recmdbanner}>
                  {this.state.recomentDisplay}
                </OwlCarousel>
              </div>
            )}
          </div>
        </div>
        {this.state.delZoneID === "" && this.state.inititalLoad === true && (
          <div className="welcomeuv textcenter">
            <div className="sry-no service-no">
              <img src={welcomeuv} />
              <h2>Welcome to UV Delivery</h2>
              <p>Please, set your address or set your eats area</p>
              <a
                href={void 0}
                onClick={this.setAddress.bind(this)}
                className="button button-full"
              >
                Set Address
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var storelistArr = Array();
  if (Object.keys(state.storelist).length > 0) {
    if (state.storelist[0].status === "ok") {
      storelistArr = state.storelist[0].result;
    }
  }
  var cartdetailsArr = Array();
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }
  var voucherListArr = Array();
  if (Object.keys(state.voucherlist).length > 0) {
    voucherListArr = state.voucherlist[0];
  }
  return {
    storeList: storelistArr,
    cartdetails: cartdetailsArr,
    voucherlist: voucherListArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStoreList: (params) => {
      dispatch({ type: GET_STORE_LIST, params });
    },
    getVoucherList: () => {
      dispatch({ type: GET_VOUCHER_LIST });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Home));
